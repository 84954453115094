<template>
  <div class="organization-container">
    <div class="content-wrap">
      <list />
    </div>
  </div>
</template>

<script>
import list from "./list.vue";
export default {
  components: {
    list
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.organization-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .custom-tree-container {
    min-width: 276px;
    height: 100%;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;

    .tree-footer {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08), -1px 0px 0px 0px #eeeeee;

      .item {
        padding: 8px 16px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid #dddddd;

        &:nth-of-type(2) {
          margin-left: 8px;
        }

        &:hover {
          cursor: pointer;
          color: #598DF3;
          background: rgba($color: #598DF3, $alpha: 0.1);
        }
      }
    }
  }

  .content-wrap {
    box-sizing: border-box;
    padding: 24px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    ::v-deep {
      .el-tabs {
        height: calc(100% - 40px);
      }

      .el-tabs__content {
        height: 100%;
      }

      .el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #dfe4ed;
    z-index: 1;
  }
}

.dropdown-delete {
  &:hover {
    cursor: pointer;
    color: #ff6600;
    background: rgba($color: #ff6600, $alpha: 0.1);
  }
}
</style>
